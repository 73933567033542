import Chart from 'chart.js/auto'
var config = require('./history_response.json');
console.log(config);
const data = [
    'cumulative_layout_shift',
    'experimental_time_to_first_byte',
    'first_contentful_paint',
    'first_input_delay',
    'interaction_to_next_paint',
    'largest_contentful_paint'
];

(async function() {
    new Chart(
        document.getElementById('good_experience'),
        {
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Good',
                        padding: {
                            top: 10,
                            bottom: 10
                        }
                    }
                }
            },
            type: 'line',
            data: {
                labels: config.record.collectionPeriods.map(row => row.lastDate.year +"/"+row.lastDate.month +"/"+row.lastDate.day  ),
                datasets:
                    data.map(key => ({
                        label: key,
                        data:  config.record.metrics[key].histogramTimeseries[0].densities.map(v=>v*100)
                    }))
            }
        }
    );
    new Chart(
        document.getElementById('med_experience'),
        {
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Needs Improvement',
                        padding: {
                            top: 10,
                            bottom: 10
                        }
                    }
                }
            },
            type: 'line',
            data: {
                labels: config.record.collectionPeriods.map(row => row.lastDate.year +"/"+row.lastDate.month +"/"+row.lastDate.day  ),
                datasets:
                    data.map(key => ({
                        label: key,
                        data:  config.record.metrics[key].histogramTimeseries[1].densities.map(v=>v*100)
                    }))
            }
        }
    );
    new Chart(
        document.getElementById('poor_experience'),
        {
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Poor',
                        padding: {
                            top: 10,
                            bottom: 10
                        }
                    }
                }
            },
            type: 'line',
            data: {
                labels: config.record.collectionPeriods.map(row => row.lastDate.year +"/"+row.lastDate.month +"/"+row.lastDate.day  ),
                datasets:
                    data.map(key => ({
                        label: key,
                        data:  config.record.metrics[key].histogramTimeseries[2].densities.map(v=>(v*100))
                    }))
            }
        }
    );
})();
