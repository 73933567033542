{
    "record": {
        "key": {
            "formFactor": "DESKTOP",
            "url": "https://www.skyvegas.com/"
        },
        "metrics": {
            "cumulative_layout_shift": {
                "histogramTimeseries": [
                    {
                        "start": "0.00",
                        "end": "0.10",
                        "densities": [
                            0.2704,
                            0.2491,
                            0.2276,
                            0.2087,
                            0.2177,
                            0.2226,
                            0.2301,
                            0.2315,
                            0.2269,
                            0.2292,
                            0.2193,
                            0.2238,
                            0.2165,
                            0.2188,
                            0.2189,
                            0.2139,
                            0.2253,
                            0.2188,
                            0.207,
                            0.2109,
                            0.2055,
                            0.2099,
                            0.2052,
                            0.2059,
                            0.2125
                        ]
                    },
                    {
                        "start": "0.10",
                        "end": "0.25",
                        "densities": [
                            0.1599,
                            0.1739,
                            0.1922,
                            0.2054,
                            0.1866,
                            0.1751,
                            0.1523,
                            0.1396,
                            0.1361,
                            0.1346,
                            0.1293,
                            0.1336,
                            0.1308,
                            0.1378,
                            0.1343,
                            0.1327,
                            0.1314,
                            0.1431,
                            0.1436,
                            0.1377,
                            0.1438,
                            0.1484,
                            0.1586,
                            0.1588,
                            0.1411
                        ]
                    },
                    {
                        "start": "0.25",
                        "densities": [
                            0.5698,
                            0.577,
                            0.5801,
                            0.5859,
                            0.5958,
                            0.6023,
                            0.6176,
                            0.6289,
                            0.637,
                            0.6362,
                            0.6514,
                            0.6425,
                            0.6527,
                            0.6435,
                            0.6468,
                            0.6534,
                            0.6434,
                            0.6381,
                            0.6494,
                            0.6514,
                            0.6507,
                            0.6417,
                            0.6362,
                            0.6353,
                            0.6464
                        ]
                    }
                ],
                "percentilesTimeseries": {
                    "p75s": [
                        "0.43",
                        "0.45",
                        "0.47",
                        "0.52",
                        "0.57",
                        "0.62",
                        "0.70",
                        "0.77",
                        "0.81",
                        "0.82",
                        "0.83",
                        "0.85",
                        "0.88",
                        "0.90",
                        "0.90",
                        "0.88",
                        "0.83",
                        "0.81",
                        "0.83",
                        "0.81",
                        "0.68",
                        "0.56",
                        "0.49",
                        "0.51",
                        "0.77"
                    ]
                }
            },
            "experimental_time_to_first_byte": {
                "histogramTimeseries": [
                    {
                        "start": 0,
                        "end": 800,
                        "densities": [
                            0.9127,
                            0.9217,
                            0.9135,
                            0.9217,
                            0.9164,
                            0.9178,
                            0.9123,
                            0.9193,
                            0.9222,
                            0.9237,
                            0.9236,
                            0.9203,
                            0.9193,
                            0.9144,
                            0.9167,
                            0.9089,
                            0.9128,
                            0.9154,
                            0.9102,
                            0.9189,
                            0.9131,
                            0.9074,
                            0.9091,
                            0.9109,
                            0.9052
                        ]
                    },
                    {
                        "start": 800,
                        "end": 1800,
                        "densities": [
                            0.0709,
                            0.0656,
                            0.0739,
                            0.0656,
                            0.0694,
                            0.0661,
                            0.0715,
                            0.0645,
                            0.0654,
                            0.062,
                            0.0639,
                            0.0655,
                            0.0681,
                            0.0728,
                            0.0688,
                            0.0747,
                            0.0723,
                            0.068,
                            0.0751,
                            0.0663,
                            0.0721,
                            0.0759,
                            0.0742,
                            0.0723,
                            0.0762
                        ]
                    },
                    {
                        "start": 1800,
                        "densities": [
                            0.0164,
                            0.0128,
                            0.0126,
                            0.0128,
                            0.0143,
                            0.0161,
                            0.0161,
                            0.0161,
                            0.0124,
                            0.0142,
                            0.0125,
                            0.0142,
                            0.0126,
                            0.0128,
                            0.0145,
                            0.0164,
                            0.0149,
                            0.0166,
                            0.0147,
                            0.0148,
                            0.0148,
                            0.0167,
                            0.0167,
                            0.0168,
                            0.0186
                        ]
                    }
                ],
                "percentilesTimeseries": {
                    "p75s": [
                        523,
                        516,
                        512,
                        514,
                        519,
                        519,
                        517,
                        515,
                        509,
                        510,
                        512,
                        512,
                        514,
                        519,
                        525,
                        534,
                        538,
                        533,
                        537,
                        537,
                        542,
                        552,
                        551,
                        553,
                        555
                    ]
                }
            },
            "first_contentful_paint": {
                "histogramTimeseries": [
                    {
                        "start": 0,
                        "end": 1800,
                        "densities": [
                            0.9069,
                            0.9158,
                            0.9111,
                            0.9171,
                            0.91,
                            0.916,
                            0.912,
                            0.9139,
                            0.9098,
                            0.9142,
                            0.9216,
                            0.9199,
                            0.9226,
                            0.9229,
                            0.9229,
                            0.9175,
                            0.9165,
                            0.9164,
                            0.9186,
                            0.92,
                            0.9084,
                            0.91,
                            0.9149,
                            0.9132,
                            0.9184
                        ]
                    },
                    {
                        "start": 1800,
                        "end": 3000,
                        "densities": [
                            0.0626,
                            0.0539,
                            0.0587,
                            0.053,
                            0.0605,
                            0.0549,
                            0.057,
                            0.0568,
                            0.0606,
                            0.0566,
                            0.0512,
                            0.0529,
                            0.0516,
                            0.0508,
                            0.0508,
                            0.0528,
                            0.0551,
                            0.0535,
                            0.0515,
                            0.05,
                            0.061,
                            0.0594,
                            0.0544,
                            0.0544,
                            0.0527
                        ]
                    },
                    {
                        "start": 3000,
                        "densities": [
                            0.0305,
                            0.0303,
                            0.0302,
                            0.0299,
                            0.0295,
                            0.0291,
                            0.031,
                            0.0293,
                            0.0295,
                            0.0292,
                            0.0273,
                            0.0273,
                            0.0258,
                            0.0262,
                            0.0262,
                            0.0297,
                            0.0284,
                            0.0301,
                            0.0299,
                            0.03,
                            0.0305,
                            0.0306,
                            0.0306,
                            0.0323,
                            0.0289
                        ]
                    }
                ],
                "percentilesTimeseries": {
                    "p75s": [
                        1119,
                        1115,
                        1108,
                        1106,
                        1114,
                        1118,
                        1115,
                        1107,
                        1089,
                        1083,
                        1074,
                        1064,
                        1058,
                        1057,
                        1062,
                        1079,
                        1090,
                        1088,
                        1092,
                        1091,
                        1100,
                        1107,
                        1101,
                        1094,
                        1081
                    ]
                }
            },
            "first_input_delay": {
                "histogramTimeseries": [
                    {
                        "start": 0,
                        "end": 100,
                        "densities": [
                            0.9484,
                            0.9503,
                            0.9536,
                            0.9557,
                            0.955,
                            0.9566,
                            0.9565,
                            0.955,
                            0.9551,
                            0.9518,
                            0.9543,
                            0.9539,
                            0.9551,
                            0.955,
                            0.9545,
                            0.9549,
                            0.9533,
                            0.9546,
                            0.9546,
                            0.9545,
                            0.9547,
                            0.9525,
                            0.9531,
                            0.9539,
                            0.954
                        ]
                    },
                    {
                        "start": 100,
                        "end": 300,
                        "densities": [
                            0.0366,
                            0.0364,
                            0.0331,
                            0.0328,
                            0.0321,
                            0.0321,
                            0.0323,
                            0.0321,
                            0.0336,
                            0.0353,
                            0.0331,
                            0.0334,
                            0.032,
                            0.0321,
                            0.0325,
                            0.0317,
                            0.0333,
                            0.0319,
                            0.0336,
                            0.0337,
                            0.0335,
                            0.0356,
                            0.0352,
                            0.0341,
                            0.0341
                        ]
                    },
                    {
                        "start": 300,
                        "densities": [
                            0.015,
                            0.0133,
                            0.0133,
                            0.0115,
                            0.0129,
                            0.0113,
                            0.0113,
                            0.0129,
                            0.0113,
                            0.0129,
                            0.0126,
                            0.0127,
                            0.0128,
                            0.0129,
                            0.013,
                            0.0134,
                            0.0134,
                            0.0135,
                            0.0118,
                            0.0118,
                            0.0118,
                            0.0119,
                            0.0117,
                            0.012,
                            0.012
                        ]
                    }
                ],
                "percentilesTimeseries": {
                    "p75s": [
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7,
                        7
                    ]
                }
            },
            "interaction_to_next_paint": {
                "histogramTimeseries": [
                    {
                        "start": 0,
                        "end": 200,
                        "densities": [
                            0.6581,
                            0.6646,
                            0.6667,
                            0.6794,
                            0.6756,
                            0.6736,
                            0.6871,
                            0.6808,
                            0.6696,
                            0.6859,
                            0.6766,
                            0.6819,
                            0.6926,
                            0.695,
                            0.6935,
                            0.6906,
                            0.6869,
                            0.6925,
                            0.6906,
                            0.6965,
                            0.6777,
                            0.678,
                            0.6829,
                            0.6977,
                            0.69
                        ]
                    },
                    {
                        "start": 200,
                        "end": 500,
                        "densities": [
                            0.2153,
                            0.2088,
                            0.2055,
                            0.1939,
                            0.2048,
                            0.2012,
                            0.1946,
                            0.1945,
                            0.2063,
                            0.1896,
                            0.2021,
                            0.1997,
                            0.1853,
                            0.1853,
                            0.1866,
                            0.1914,
                            0.1963,
                            0.1972,
                            0.1984,
                            0.184,
                            0.1959,
                            0.2028,
                            0.1884,
                            0.1752,
                            0.1916
                        ]
                    },
                    {
                        "start": 500,
                        "densities": [
                            0.1266,
                            0.1266,
                            0.1279,
                            0.1267,
                            0.1196,
                            0.1252,
                            0.1183,
                            0.1246,
                            0.1241,
                            0.1245,
                            0.1213,
                            0.1184,
                            0.1221,
                            0.1196,
                            0.1199,
                            0.118,
                            0.1169,
                            0.1103,
                            0.111,
                            0.1195,
                            0.1264,
                            0.1192,
                            0.1288,
                            0.127,
                            0.1184
                        ]
                    }
                ],
                "percentilesTimeseries": {
                    "p75s": [
                        285,
                        281,
                        276,
                        277,
                        274,
                        271,
                        272,
                        272,
                        271,
                        272,
                        265,
                        258,
                        256,
                        254,
                        252,
                        253,
                        252,
                        250,
                        253,
                        258,
                        263,
                        268,
                        266,
                        261,
                        252
                    ]
                }
            },
            "largest_contentful_paint": {
                "histogramTimeseries": [
                    {
                        "start": 0,
                        "end": 2500,
                        "densities": [
                            0.4436,
                            0.451,
                            0.4455,
                            0.4577,
                            0.463,
                            0.4591,
                            0.458,
                            0.475,
                            0.5049,
                            0.4892,
                            0.4828,
                            0.4984,
                            0.5129,
                            0.5242,
                            0.5163,
                            0.5121,
                            0.5076,
                            0.5126,
                            0.4949,
                            0.5017,
                            0.5094,
                            0.5155,
                            0.5079,
                            0.5228,
                            0.5246
                        ]
                    },
                    {
                        "start": 2500,
                        "end": 4000,
                        "densities": [
                            0.2179,
                            0.2122,
                            0.2206,
                            0.2055,
                            0.2104,
                            0.2124,
                            0.2114,
                            0.2083,
                            0.1992,
                            0.2073,
                            0.1969,
                            0.2045,
                            0.1893,
                            0.197,
                            0.2046,
                            0.2009,
                            0.2071,
                            0.205,
                            0.1996,
                            0.2034,
                            0.1898,
                            0.1971,
                            0.1928,
                            0.197,
                            0.1936
                        ]
                    },
                    {
                        "start": 4000,
                        "densities": [
                            0.3386,
                            0.3368,
                            0.3339,
                            0.3368,
                            0.3266,
                            0.3285,
                            0.3306,
                            0.3167,
                            0.2959,
                            0.3035,
                            0.3203,
                            0.2971,
                            0.2978,
                            0.2788,
                            0.2791,
                            0.287,
                            0.2852,
                            0.2824,
                            0.3055,
                            0.2949,
                            0.3007,
                            0.2875,
                            0.2993,
                            0.2802,
                            0.2817
                        ]
                    }
                ],
                "percentilesTimeseries": {
                    "p75s": [
                        5207,
                        5146,
                        5128,
                        5147,
                        5119,
                        5057,
                        4960,
                        4860,
                        4803,
                        4799,
                        4694,
                        4573,
                        4534,
                        4465,
                        4470,
                        4517,
                        4535,
                        4513,
                        4540,
                        4582,
                        4600,
                        4659,
                        4652,
                        4595,
                        4516
                    ]
                }
            },
            "navigation_types": {
                "fractionTimeseries": {
                    "back_forward": {
                        "fractions": [
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            0.0064,
                            0.0064,
                            0.0049,
                            0.005,
                            0.0067,
                            0.0066,
                            0.0066,
                            0.0067,
                            0.0067,
                            0.0067,
                            0.0068,
                            0.0085,
                            0.0068
                        ]
                    },
                    "back_forward_cache": {
                        "fractions": [
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ]
                    },
                    "prerender": {
                        "fractions": [
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            0.091,
                            0.0982,
                            0.0918,
                            0.0862,
                            0.0734,
                            0.0747,
                            0.0731,
                            0.0684,
                            0.0737,
                            0.0789,
                            0.0747,
                            0.0731,
                            0.0753
                        ]
                    },
                    "navigate": {
                        "fractions": [
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            0.861,
                            0.8551,
                            0.8623,
                            0.8657,
                            0.8798,
                            0.8804,
                            0.8804,
                            0.8814,
                            0.881,
                            0.8792,
                            0.8862,
                            0.8775,
                            0.8801
                        ]
                    },
                    "navigate_cache": {
                        "fractions": [
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0,
                            0
                        ]
                    },
                    "reload": {
                        "fractions": [
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            0.0335,
                            0.0322,
                            0.0328,
                            0.0348,
                            0.0334,
                            0.0299,
                            0.0332,
                            0.035,
                            0.0318,
                            0.0285,
                            0.0255,
                            0.034,
                            0.0308
                        ]
                    },
                    "restore": {
                        "fractions": [
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            "NaN",
                            0.008,
                            0.008,
                            0.0082,
                            0.0083,
                            0.0067,
                            0.0083,
                            0.0066,
                            0.0083,
                            0.0067,
                            0.0067,
                            0.0068,
                            0.0068,
                            0.0068
                        ]
                    }
                }
            }
        },
        "collectionPeriods": [
            {
                "firstDate": {
                    "year": 2023,
                    "month": 12,
                    "day": 3
                },
                "lastDate": {
                    "year": 2023,
                    "month": 12,
                    "day": 30
                }
            },
            {
                "firstDate": {
                    "year": 2023,
                    "month": 12,
                    "day": 10
                },
                "lastDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 6
                }
            },
            {
                "firstDate": {
                    "year": 2023,
                    "month": 12,
                    "day": 17
                },
                "lastDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 13
                }
            },
            {
                "firstDate": {
                    "year": 2023,
                    "month": 12,
                    "day": 24
                },
                "lastDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 20
                }
            },
            {
                "firstDate": {
                    "year": 2023,
                    "month": 12,
                    "day": 31
                },
                "lastDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 27
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 7
                },
                "lastDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 3
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 14
                },
                "lastDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 10
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 21
                },
                "lastDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 17
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 1,
                    "day": 28
                },
                "lastDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 24
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 4
                },
                "lastDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 2
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 11
                },
                "lastDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 9
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 18
                },
                "lastDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 16
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 2,
                    "day": 25
                },
                "lastDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 23
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 3
                },
                "lastDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 30
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 10
                },
                "lastDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 6
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 17
                },
                "lastDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 13
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 24
                },
                "lastDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 20
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 3,
                    "day": 31
                },
                "lastDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 27
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 7
                },
                "lastDate": {
                    "year": 2024,
                    "month": 5,
                    "day": 4
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 14
                },
                "lastDate": {
                    "year": 2024,
                    "month": 5,
                    "day": 11
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 21
                },
                "lastDate": {
                    "year": 2024,
                    "month": 5,
                    "day": 18
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 4,
                    "day": 28
                },
                "lastDate": {
                    "year": 2024,
                    "month": 5,
                    "day": 25
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 5,
                    "day": 5
                },
                "lastDate": {
                    "year": 2024,
                    "month": 6,
                    "day": 1
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 5,
                    "day": 12
                },
                "lastDate": {
                    "year": 2024,
                    "month": 6,
                    "day": 8
                }
            },
            {
                "firstDate": {
                    "year": 2024,
                    "month": 5,
                    "day": 19
                },
                "lastDate": {
                    "year": 2024,
                    "month": 6,
                    "day": 15
                }
            }
        ]
    }
}